<template>
  <form class="py-8 w-full">
    <label for="user-note-field__textarea" class="text-blue text-lg font-light">Escreva aqui suas anotações</label>
    <textarea v-model="note" name="user-note" id="user-note-field__textarea" class="user-note-field__textarea resize-none w-full py-5 text-lg focus:outline-none text-blue" rows="5"></textarea>
    <span class="text-blue font-light text-sm block text-right">{{note.length}} / 560</span>
    <Button class="mt-8 text-center block mx-auto" @click="save">Salvar</Button>
  </form>
</template>

<script>
import api from '@/utils/api.js';
import { mapState } from 'vuex';
import Button from "@/components/Button";

export default {
  name: "UserNoteField",
  components: {
    Button,
  },
  computed: {
    ...mapState(['userModal']),
  },
  data() {
    return {
      saving: false,
      note: '',
    }
  },
  methods: {
    async save() {
      if (this.saving) {
        return;
      }

      this.saving = true;

      try {
        await api.post(`users/${this.userModal.user.id}/notes`, {
          note: this.note
        });

        this.note = '';
        this.$noty.success('A anotação foi salva.');
        this.$emit('done');
      } catch (e) {
        this.$noty.success('Ocorreu um erro ao salvar a anotação.');
      }

      this.saving = false;
    }
  }
};
</script>

<style lang="scss">
.user-note-field__textarea {
  border-bottom: 1.5px solid rgba(0, 19, 111, 0.25);
}
</style>
