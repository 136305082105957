<template>
  <section
    class="change-product-modal fixed top-0 right-0 bottom-0 overflow-y-scroll z-10 bg-cream py-10 px-12 transform transition duration-300 ease-in-out"
    :class="open ? 'translate-x-0' : 'translate-x-full'"
  >
    <div v-if="product">
      <header class="w-1/3 text-center border border-orange border-opacity-25 rounded-lg p-4 mx-auto mb-8">
        <p class="opacity-70">
          <template v-if="product.pivot.period === 'day'">Ao acordar</template>
          <template v-else>Ao dormir</template>
        </p>

        <p class="flex items-center justify-center text-orange">
          <span class="font-medium text-xl mr-3">{{ index + 1 }}</span> {{ product.category.title }}
        </p>
      </header>

      <h2 class="w-10/12 text-blue text-center text-3xl mb-10 mx-auto">Selecione outro produto para esta rotina</h2>

      <Loader :isloading="loading" />
      <ul class="grid grid-cols-3 gap-3" v-if="!loading">
        <li
          v-for="product in products"
          :key="product.id"
          @click="select(product)"
        >
          <product
            :product="product"
            class="flex-1 h-full border-2 border-white"
            :class="{
              'border-danger': selectedProduct === product.id
            }"
          />
        </li>
      </ul>
    </div>

    <div class="flex justify-between mt-12">
      <Button ghost @click="close">Cancelar</Button>
      <Button class="w-32" @click="save" :loading="saving">Salvar</Button>
    </div>
  </section>
</template>

<script>
import api from '@/utils/api.js';
import Product from '@/components/Product';
import Button from '@/components/Button';
import Loader from '@/components/Loader';

export default {
  name: 'ChangeProduct',
  components: { Loader, Button, Product },
  props: ['open', 'plan', 'product', 'index'],
  data() {
    return {
      loading: false,
      saving: false,
      products: [],
      selectedProduct: null
    }
  },
  watch: {
    product() {
      if (this.product) {
        this.selectedProduct = this.product.id;
        this.products = [];
        this.fetchSimilar();
      }
    }
  },
  methods: {
    async fetchSimilar() {
      this.loading = true;
      const response = await api.get(`products/${this.product.id}/similar`, {
        params: {
          category: this.product.category.id
        }
      });

      this.products = response.data;
      this.loading = false;
    },

    select(product) {
      this.selectedProduct = product.id;
    },

    close() {
      this.$emit('close');
    },

    async save() {
      this.saving = true;

      try {
        await api.put(`plans/${this.plan.id}/change-product`, {
          from: this.product.id,
          to: this.selectedProduct,
          period: this.product.pivot.period,
        });

        this.$noty.success('Produto alterado!');
      } catch (e) {
        console.log(e);
        this.$noty.error('Ocorreu um erro ao alterar o produto');
      }

      this.saving = false;
      this.close();
    },
  }
};
</script>

<style lang="scss">

</style>
