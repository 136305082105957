<template>
  <div class="product relative bg-white p-4 text-center rounded-lg">
    <div class="product-header">
      <img :src="product.image" alt="" class="block mx-auto" />
    </div>

    <h3 class="font-light text-sm mt-3">{{ product.short_name || product.name }}</h3>

    <span
      class="absolute top-3 left-3 w-2 h-2 block rounded-full"
      :class="product.is_active ? 'bg-success' : 'bg-danger'"
      v-if="showStatus"
    />
  </div>
</template>

<script>
export default {
  name: "Product",
  props: {
    product: Object,
    showStatus: Boolean
  }
};
</script>

<style lang="scss">
  .product {
    box-shadow: 0 2px 20px rgba(0, 19, 111, 0.04);
  }

  .product-header {
    min-height: 111px;

    .products-list & {
      @screen xl {
        min-height: 200px;
      }
    }
  }
</style>
