<template>
  <div class="user-modal fixed top-0 left-0 w-full h-full opacity-0">
    <div class="user-modal-card relative bg-white p-12 ml-auto h-screen">
      <header class="user-modal-header relative mb-10">
        <avatar class="w-20 h-20 mx-auto mb-5" :image="user.avatar" :name="user.name" />
        <h3 class="text-center font-semibold text-xl text-blue">{{ user.name }}</h3>
        <h6 class="text-center text-blue">{{ user.email }}</h6>
        <h6 class="text-center text-blue">{{ user.phone }}</h6>
        <button
          class="user-modal-close absolute -top-8 -right-8 w-10 h-10 flex items-center justify-center focus:outline-none"
          @click="closeUserModal"
          v-if="!inCall"
        >
          <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.081.332a1.133 1.133 0 00-1.602 0L7.707 6.104 1.935.332A1.133 1.133 0 00.332 1.935l5.772 5.772-5.772 5.772a1.133 1.133 0 001.603 1.602L7.707 9.31l5.772 5.773a1.134 1.134 0 001.603-1.603L9.309 7.707l5.772-5.772a1.133 1.133 0 000-1.603z"
              fill="#00136F"
            />
          </svg>
        </button>
      </header>

      <div class="user-modal-body">
        <div class="user-modal-tabs flex mb-2">
          <button
            class="user-modal-tab relative flex-1 text-blue-100 font-light text-lg p-2 mb-2 focus:outline-none"
            :class="{
              'user-modal-tab--active': selectedTab === 'perfil',
            }"
            @click="changeTab('perfil')"
          >
            Perfil
          </button>

          <button
            class="user-modal-tab relative flex-1 text-blue-100 font-light text-lg p-2 mb-2 focus:outline-none"
            :class="{
              'user-modal-tab--active': selectedTab === 'plano',
            }"
            @click="changeTab('plano')"
          >
            Plano
          </button>

          <button
            class="user-modal-tab relative flex-1 text-blue-100 font-light text-lg p-2 mb-2 focus:outline-none"
            :class="{
              'user-modal-tab--active': selectedTab === 'anotacoes',
            }"
            @click="changeTab('anotacoes')"
          >
            Anotações
          </button>

        </div>

        <div
          v-if="selectedTab === 'perfil'"
          class="user-modal-tab__content"
        >
          <table class="w-full">
            <tr>
              <th class="text-left">Idade</th>
              <td>{{ age }} anos</td>
            </tr>

            <tr v-for="question in questions" :key="question.id">
              <th class="text-left">{{ question.short_title }}</th>
              <td>
                <span class="flex items-center" v-for="answer in question.answers" :key="`answer-${answer.id}`">
                  <span
                    class="block w-6 h-6 rounded-full mr-3"
                    v-if="answer.color"
                    :style="{ backgroundColor: answer.color }"
                  />

                  {{ answer.title }}
                </span>
              </td>
            </tr>
          </table>
        </div>

        <div v-if="selectedTab === 'plano'" class="user-modal-tab__content mt-10">
          <h3 class="font-light opacity-80 font-medium text-black mb-5">Ao acordar</h3>

          <ul class="user-modal-products border-b border-orange border-opacity-25 mb-8">
            <li class="flex flex-col" v-for="(product, index) in dayProducts" :key="`day-${product.id}`">
              <p class="user-modal-category">
                <span class="font-medium text-xl mr-3">{{ index + 1 }}</span> {{ product.category.title }}
              </p>

              <div class="relative flex-1">
                <product class="h-full" :product="product" />
                <edit-button @click="edit(product, index)" />
              </div>
            </li>
          </ul>

          <h3 class="font-light opacity-80 font-medium text-black mb-5">Antes dormir</h3>

          <ul class="user-modal-products">
            <li class="relative flex flex-col" v-for="(product, index) in nightProducts" :key="`night-${product.id}`">
              <p class="user-modal-category">
                <span class="font-medium text-xl mr-3">{{ index + 1 }}</span> {{ product.category.title }}
              </p>

              <div class="relative flex-1">
                <product class="h-full" :product="product"/>
                <edit-button @click="edit(product, index)" />
              </div>
            </li>
          </ul>
        </div>

        <div v-if="selectedTab === 'anotacoes'" class="user-modal-tab__content user-modal-tab__content--anotacoes">
          <div class="register-note mt-8 mb-10" v-if="plan.isConsultant">
            <p class="font-light text-blue">Registre as principais dúvidas, feedbacks ou sugestões que surgiram durante este atendimento.</p>
            <user-note-field @done="fetchNotes" />
          </div>

          <div class="user-modal-notes relative" :class="{ 'user-modal-notes--in-call' : inCall }">
            <h5 class="text-blue text-lg -mb-4 pt-6" v-if="inCall">Anotações Anteriores</h5>

            <user-note
              v-for="note in notes"
              :key="note.id"
              :note="note"
            />

            <p class="my-10" v-if="!notes.length">Nenhuma anotação encontrada.</p>
          </div>
        </div>
        <div class="flex justify-end mt-12" v-if="actions === 'edit'">
          <Button ghost light @click="openDeleteRegisterModal" v-if="user.id !== 1 && !plan.isConsultant" >
            <svg width="16" height="20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M12 7v10H4V7h8zm-1.5-6h-5l-1 1H1v2h14V2h-3.5l-1-1zM14 5H2v12c0 1.1.9 2 2 2h8c1.1 0 2-.9 2-2V5z" fill="#00136F" stroke="#fff" stroke-width=".5"/></svg>
          </Button>
        </div>

        <div class="user-modal-actions flex items-center justify-between mt-5" v-if="reservation.id && !reservation.canceled_at && !inCall">
          <button class="user-modal-cancel-button py-2 px-8 bg-white mr-8 focus:outline-none" @click="setCancelCallModal(true)">
            <span class="text-sm">Cancelar atendimento</span>
            <span class="block text-xs">{{ date }}</span>
          </button>

          <router-link
            :to="{ name: 'Video', params: { room: reservation.id } }"
            @click.native="closeUserModal"
            class="user-modal-call-button inline-flex items-center justify-center w-24 h-16 bg-blue"
          >
            <svg width="24" height="24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M21.005 16.063L17.83 15.7a2.488 2.488 0 00-2.05.713l-1.05 1.05a18.807 18.807 0 01-8.238-8.238l1.063-1.063c.537-.537.8-1.287.712-2.05l-.362-3.15A2.502 2.502 0 005.417.75H3.255C1.842.75.667 1.925.755 3.337c.662 10.675 9.2 19.2 19.862 19.863 1.413.088 2.588-1.087 2.588-2.5v-2.162a2.477 2.477 0 00-2.2-2.476z"
                stroke="#fff"
                stroke-width="1.5"
              />
            </svg>
          </router-link>
        </div>

        <div v-if="reservation.canceled_at" class="text-center font-bold text-blue mt-5">
          Consulta cancelada
        </div>
      </div>

      <change-product
        :open="isChangeOpen"
        :plan="plan"
        :product="selectedProduct"
        :index="selectedProductIndex"
        @close="closeChange"
      />
    </div>

    <span v-if="!inCall" class="user-modal-layer fixed top-0 left-0 w-full h-full bg-black opacity-0 cursor-pointer" @click="close" />
  </div>
</template>

<script>
import { mapMutations, mapState } from 'vuex';
import dayjs from 'dayjs';
import api from '@/utils/api.js';

import Product from '@/components/Product';
import UserNoteField from '@/components/user-note/UserNoteField';
import UserNote from '@/components/user-note/UserNote';
import Avatar from '@/components/Avatar';
import ChangeProduct from '@/components/ChangeProduct';
import EditButton from '@/components/EditButton';
import Button from '@/components/Button.vue';

export default {
  name: 'UserModal',
  props: ['actions'],
  components: {
    Button,
    EditButton,
    ChangeProduct,
    Avatar,
    UserNote,
    UserNoteField,
    Product
  },
  computed: {
    ...mapState(['hasUserModal', 'inCall', 'userModal']),

    user() {

      return this.userModal.user;
    },
   
    age() {
      if (!this.user.birthdate) {
        return 0;
      }

      return Math.abs(dayjs(this.user.birthdate).diff(dayjs(), 'year'));
    },

    reservation() {
      return this.userModal.reservation;
    },
    date() {
      return dayjs(this.reservation.date).format('DD/MM [às] H:mm');
    },
    dayProducts() {
      return this.plan.products
        .filter((p) => p.pivot.period === 'day')
        .sort((a, b) => a.category.order - b.category.order);
    },
    nightProducts() {
      return this.plan.products
        .filter((p) => p.pivot.period === 'night')
        .sort((a, b) => a.category.order - b.category.order);
    },
  },
  data() {
    return {
      selectedTab: 'perfil',
      selectedProduct: null,
      selectedProductIndex: null,
      isChangeOpen: false,
      notes: [],
      questions: [],
      products: [],
      plan: {
        products: []
      },
      
      
    };
  },
  watch: {
    'user.id': {
      immediate: true,
      handler() {
        this.updateUser();
      }
    }
  },
  methods: {
    ...mapMutations(['closeUserModal', 'setCancelCallModal', 'openDeleteRegisterModal']),

    changeTab(name) {
      this.selectedTab = name;
    },

    updateUser() {
      if (!this.user.id) {
        return;
      }

      this.fetchQuestions();
      this.fetchPlan();
      this.fetchNotes();
      
    },

    edit(product, index) {
      this.isChangeOpen = true;
      this.selectedProduct = product;
      this.selectedProductIndex = index;
    },

    async fetchQuestions() {
      this.questions = [];
      const response = await api.get(`users/${this.user.id}/questions`);
      this.questions = response.data;
    },

    async fetchPlan() {
      const response = await api.get(`users/${this.user.id}/plan`);
      this.plan = response.data;
    },

    async fetchNotes() {
      const response = await api.get(`users/${this.user.id}/notes`);
      this.notes = response.data;
    },

    closeChange() {
      this.isChangeOpen = false;
      this.fetchPlan();

      setTimeout(() => {
        this.selectedProduct = null;
      }, 400);
    },

    close() {
      this.closeChange();
      this.closeUserModal();
      this.selectedTab = 'perfil';
    }
  }
};
</script>

<style lang='scss'>
.has-user-modal {
  .user-modal {
    @apply opacity-100;
  }

  .user-modal-layer {
    @apply opacity-30 pointer-events-auto;
  }

  .user-modal-card {
    @apply transform-none pointer-events-auto;
  }
}

.user-modal {
  z-index: 1;
  bottom: 0;
  pointer-events: none;
  transition: opacity 0.3s;
}

.user-modal-layer {
  z-index: -1;
}

.user-modal-card,
.change-product-modal {
  width: 560px;
}

.user-modal-card {
  z-index: 1;
  box-shadow: -2px 0px 30px rgba(0, 0, 0, 0.08);
  transform: translateX(560px);
  transition: transform 0.3s;
  overflow-y: scroll;
}

.user-modal-tab {
  border-bottom: 1px solid rgba(81, 103, 207, 0.3);
}

.user-modal-tab--active {
  &:before {
    content: "";
    position: absolute;
    bottom: -2px;
    left: 0;

    width: 100%;
    height: 2px;

    background: theme("colors.blue-100");
  }
}

.user-modal-body {
  tr {
    @apply flex items-start py-4;

    + tr {
      border-top: 1px solid rgba(246, 155, 139, 0.25);
    }
  }

  th,
  td {
    @apply flex-1;
  }

  th {
    @apply font-light;
  }
}

.user-modal-products {
  @apply grid grid-cols-3 gap-3 pb-16;

  .edit-button {
    @apply absolute;
    top: 15px;
    right: 15px;
  }
}

.user-modal-category {
  @apply flex items-center leading-none text-orange mb-3;
  min-height: 52px;
}

.user-plan-table {
  span {
    @apply block;
  }
}

.user-modal-cancel-button {
  color: rgba(0, 19, 111, 0.7);
  border: 1px solid rgba(0, 19, 111, 0.25);
}

.user-modal-cancel-button,
.user-modal-call-button {
  border-radius: 60px;
}
</style>
