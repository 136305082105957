<template>
  <button class="edit-button focus:outline-none" @click="$emit('click')">
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.5">
        <path d="M14 0C6.2681 0 0 6.2681 0 14C0 21.7319 6.2681 28 14 28C21.7319 28 28 21.7319 28 14C27.9913 6.27174 21.7283 0.00865888 14 0ZM14 27.0667C6.78353 27.0667 0.933333 21.2165 0.933333 14C0.933333 6.78353 6.78353 0.933333 14 0.933333C21.2165 0.933333 27.0667 6.78353 27.0667 14C27.0585 21.2131 21.2131 27.0585 14 27.0667Z" fill="#00136F"/>
        <path d="M19.5998 19.1331H12.1661L18.6762 12.623L19.3361 11.9633C20.2405 11.0509 20.2373 9.57894 19.3288 8.67067C18.4206 7.76217 16.9486 7.75898 16.0362 8.66338L15.3765 9.32327L8.77689 15.9229L8.77256 15.9284C8.73519 15.968 8.70488 16.0138 8.68301 16.0637C8.67754 16.077 8.67367 16.0895 8.66911 16.1025C8.66159 16.12 8.65521 16.138 8.64997 16.1562L7.9429 19.5031C7.93835 19.5296 7.9363 19.5562 7.9363 19.5829C7.9363 19.589 7.93311 19.5936 7.93311 19.5997V19.6068C7.93447 19.6608 7.94518 19.7141 7.96478 19.7645C7.96956 19.7761 7.97412 19.7868 7.97982 19.798C8.00124 19.8463 8.03109 19.8905 8.06755 19.9288C8.1555 20.0172 8.27513 20.0669 8.39977 20.0664H19.5998C19.8575 20.0664 20.0664 19.8575 20.0664 19.5997C20.0664 19.342 19.8575 19.1331 19.5998 19.1331ZM10.8078 18.6142L9.00635 18.9932L9.38734 17.1898L10.0985 17.901L10.8078 18.6142ZM16.6961 9.32327C17.2505 8.79508 18.1218 8.79508 18.6762 9.32327C19.2231 9.86992 19.2231 10.7565 18.6762 11.3034L18.3458 11.6334L16.3661 9.65368L16.6961 9.32327ZM15.7065 10.3131L16.6961 11.303L17.6864 12.293L11.7462 18.2328L10.7563 17.2432L9.76673 16.2529L15.7065 10.3131Z" fill="#00136F"/>
      </g>
    </svg>
  </button>
</template>

<script>
export default {
  name: 'EditButton',
}
</script>

<style lang="scsss">

</style>
