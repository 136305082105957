<template>
  <div class="user-note py-8">
    <h4 class="text-xs text-black opacity-70 mb-1">{{ note.author.name }}</h4>
    <p class="text-xs text-black font-light opacity-70 mb-2">
      {{ date }}
    </p>

    <div class="user-note-body">
      <p>{{ note.content }}</p>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';

export default {
  name: "UserNote",
  props: ['note'],
  computed: {
    date() {
      return dayjs(this.note.created_at).format('DD/MM [às] H:mm');
    }
  }
};
</script>

<style lang="scss">
.user-note {
  & + & {
    border-top: 1px solid rgba(246, 155, 139, 0.25);
  }
}

.user-note-body {
  @apply font-light;

  p {
    @apply leading-7;

    + p {
      @apply mt-6;
    }
  }
}
</style>
